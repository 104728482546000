@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

:root {
  --sage-black: #353535;
  --sage-sand: #b5650e;
  --sage-cream: #fcf7ed;
  --sage-brown: #743200;
  --white: white;
  --black: #353535;
  --dark-background: #353535; /* Dark background */
  --dark-text: #e0e0e0; /* Light grey text for dark mode */
  --dark-secondary: #b5650e; /* Secondary color for dark mode */
}

body {
  background-color: var(--sage-cream);
}

body.light-mode {
  background-color: var(--sage-cream);
  color: var(--black);
}

body.dark-mode {
  background-color: var(--dark-background);
  color: var(--dark-text);
}

.loading-indicator {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  font-size: clamp(0.8rem, 2.5vw, 0.5rem);
  color: inherit;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: monospace;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
  color: inherit;
}

h1 {
  font-size: clamp(1rem, 4vw, 2rem);
  margin: 0;
  font-family: 'Lexend Deca', sans-serif;
  text-transform: uppercase;
  font-weight: 100;
}

h2 {
  font-size: clamp(1.2rem, 6vw, 3rem);
  margin: 0;
  font-family: 'Lexend Deca', sans-serif;
  text-transform: uppercase;
  font-weight: 300;
}

.bottom-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.bottom-left-text, .brisbane-time {
  z-index: 10;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: monospace;
  color: inherit;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s;
  color: inherit;
}

.icon:hover {
  transform: scale(1.2);
  color: var(--dark-secondary); /* Change icon color on hover in dark mode */
}

.top-right-text {
  position: absolute;
  top: 5px;
  display: flex;
  right: 20px;
  z-index: 10;
  font-size: clamp(0.8rem, 2.5vw, 0.5rem);
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Lexend Deca', sans-serif;
  color: inherit;
}

.line-1 {
  position: relative;
  margin: 0 auto;
  border-right: 2px solid rgba(255,255,255,.75);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
  color: inherit;
  margin-top: 10px;
  font-family: monospace;
  font-size: clamp(0.8rem, 2.5vw, 0.5rem);
}

/* Animation */
.anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
           blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter {
  from { width: 0; }
  to { width: 25em; }
}

@keyframes blinkTextCursor {
  from { border-right-color: rgba(255, 53, 53, 0.75); }
  to { border-right-color: transparent; }
}

.toggle-dark-mode {
  z-index: 10;
  padding: 10px 0px 10px 20px;
  color: var(--dark-text);
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Lexend Deca', sans-serif;
}

@media (max-width: 768px) {
  .bottom-container {
    flex-direction: column;
    align-items: center;
  }
  .bottom-left-text, .brisbane-time {
    margin-bottom: 10px;
    text-align: center;
  }
  .line-1 {
    margin-top: 20px;
  }
}
